.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-6 {
  gap : 3rem;
}

.p-focus {
  box-shadow: none !important;
  border-color: #015ccd;
}

.active-card {
  border-left: 5px solid;
  border-left-color: rgb(49, 67, 97);
  border-radius: 10px;
  box-shadow: 0px 3px 14px 0px #3143611f;
}

::-webkit-scrollbar {
  display: none;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #d8dbe1;
  border-radius: 8px;
}

.top-bar {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  top: 0;
  left: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #314361 !important;
}
.p-inputtext:enabled:hover {
    border-color: #314361 !important;
}

.custom-tooltip .p-tooltip-text {
  background-color:rgba(255, 255, 255, 1) !important;
  color: rgba(67, 83, 111, 1) !important;
  display: flex;
  align-items: center;
  font-size: 0.725rem !important;
  border-radius: 0.5rem;
  padding: 0.2rem !important
} 

.preview-pane-tooltip .p-tooltip-text {
    background-color:rgba(255, 255, 255, 1) !important;
    color: rgba(67, 83, 111, 1) !important;
    display: flex;
    height: 2rem;
    align-items: center;
    font-size: 0.725rem !important;
    border-radius: 0.5rem;
} 
.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: white !important;
  } 
 .p-tooltip-top .p-tooltip-arrow {
    border-top-color: white !important;
  } 
.p-tooltip-left .p-tooltip-arrow {
    border-left-color: white !important;
  }   
.p-tooltip-right .p-tooltip-arrow {
    border-right-color: white !important;
  } 
.hidden {
  display: none;
}

.pre-wrap-ellipsis {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.word-break {
  text-align: center;
  overflow-wrap: anywhere;
}

.scroll-bar-visible>::-webkit-scrollbar {
  display: block;
}

.overflow-wrap-anywhere{
  overflow-wrap: anywhere;
}

.maxchar-1{
  max-width: 35ch;
}

.icon-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: var(--N20);
  cursor: pointer;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}

.lsq-data-table.p-datatable .p-datatable-thead > tr > th {
  font-family: "Nunito",sans-serif !important;
  font-style: normal !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  line-height: 1.125rem !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #667085 !important;
}
.lsq-data-table.p-datatable .p-datatable-wrapper {
  height: 100% !important;
  overflow: scroll !important;
}
.lsq-data-table.p-datatable .p-column-header-content .p-column-title{
  width: 100%;
}

.visible-on-hover{
  opacity: 0; 
  transition: opacity 0.2s;
}
.visible-on-hover:hover {
  opacity: 1;
}
.lsq-icon-box { 
  background-color: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius : 8px;
}

.backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--N0);
  animation: fadein 0.4s;
  z-index: 5;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(-50px, -50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.toast_container {
  position: absolute;
  width: 14.563rem;
  right: 0.4rem;
  top: 0;
  z-index: 9;
}

.mobile-screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  background: #eff0f2;
  border: 2px solid #d8dbe1;
  border-radius: 12px;
  height: 80vh;
  width: calc((9 / 19) * 80vh);
  min-height: 500px;
  min-width: calc((9 / 19) * 500px);
  aspect-ratio: 9/19;
  overflow: hidden;
  position: relative;
}

.horizontal-divider{
  border: 1px solid #EFEFF0;
  width: 100%;
}

.vertical-divider{
  border: 1px solid #EFEFF0;
  height: 100%;
}

.box-border{
  border : 1px solid #EFEFF0;
  border-radius: 0.7rem;
}

.icon-placeholder path{
  fill: var(--N60);
}

.blur-background{
  backdrop-filter: blur(4px);
  --webkit-backdrop-filter: blur(4px);
}
/* Add this CSS to style the resize handle cursor */
.react-grid-item > .react-resizable-handle.react-resizable-handle-e{
  bottom: 0px !important;
  transform: translateY(50%) !important; 
  right: -6px !important;
  cursor: ew-resize !important;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s{
  bottom: -6px !important;
  right: 4px !important;
  cursor: ns-resize !important;
}
.react-grid-item {
  border-radius: 11px !important;
}


.progress-container {
  width: 80%;
  background-color: #e0e0de;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
}

.progress-bar {
  height: 6px;
  width: 100%;
  display: flex;
}

.segment {
  height: 100%;
}



.widget{
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
}
.widget-preview{
  border: none;
  height: 100%;
  border-radius: 12px;
}
  .widget-preview> div{
    border-radius: 8px;
  }

/* src/WidgetPreviewCentralPanel.css */
.widget-selected {
  border: 3px solid #878FDE; 
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5); 
}
.border-top-left-radius{
  border-top-left-radius:8px;
}

.set-threshold {
  padding: 12px;
  background-color: var(--N20);
  border-radius: 12px;
}

.set-threshold .min-max{
  gap: 8px;
}
.set-threshold .lsq-input,
.set-threshold .min-max .lsq-label {
  background: var(--N20) !important;
}

.multi-range-slider .labels {
  display: none;
}
.multi-range-slider .bar > div {
  box-shadow: none;
  border: 0px;
}
.multi-range-slider .bar .caption {
  display: flex;
  border: 1px solid;
  bottom: -20px;
  margin-right: 4px;
}
.multi-range-slider .bar .caption span {
  border: 1px solid var(--N40);
  box-shadow: none;
  padding: 3px 12px;
  border-radius: 6px;
  width: auto;
  min-width: auto;
  min-height: auto;
  height: auto;
  line-height: 16px;
  background-color: white;
  color: var(--N400);
  font-size: 16px;
}
.multi-range-slider .bar .thumb {
  zoom: 60%;
  top: 3px;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is above other content */
}

.modal-content {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensure modal content is above the background */
  max-width: 500px;
  width: 100%;
  position: relative; /* Ensure it contains absolutely positioned elements */
  box-sizing: border-box;
  /* Centering content */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.svg-container {
  width: 30px; /* Desired width */
  height: 25px; /* Desired height */
  display: inline-block;
}

.svg-container svg {
  width: 100%;
  height: 100%;
}
.widgte-preview{
  margin: 10px;
  border: 1px solid #ccc;
}
.react-grid-item{
  border: 3px solid transparent;
}

.react-grid-item:hover {
  border: 3px solid #878FDE;
}
.webPreviewMode .react-grid-item:hover, .webPreviewMode .react-grid-item  {
  border: none
}
.widget-container {
  position: relative;
  overflow: visible;
  /* border: 1px solid #e7e0e0; */
}

.widget-container .drag-handler {
  display: none;
}

.widget-container:hover .drag-handler {
  display: block;
}
.non-draggable{
  cursor: pointer !important;
}
/* Custom CSS for resizing handle */
/* Hide the default resize handle */
.react-grid-item > .react-resizable-handle::after{
  visibility: hidden;
}
.resizable-widget .react-resizable-handle {
  width: 0;
  height: 0;
  background: none;
  background-image: none;
  border: none;
  visibility: hidden; /* Hide by default */
}

.resizable-widget:hover .react-resizable-handle {
  visibility: visible; /* Show on hover */
  width: 10px;
  height: 10px;
  background-image: url("/public/images/circle-resize.svg"); 
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 5;
}
.saved-changes{
  background: #F6F6F7;
  padding: 2px 10px;
    /* border: 1px solid #ccc; */
  border-radius: 5px;
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
